.grecaptcha-badge {
  visibility: hidden;
}

.label-class {
  @apply absolute text-sm text-[#427dab] dark:text-blue-200 dark:bg-bgDarkColor -translate-y-[19px] translate-x-[5px] top-2 z-[9] origin-[0] bg-white px-1 peer-focus:text-blue-600 left-1;
}

.input-class {
  @apply !shadow focus:!shadow !border-[1px] placeholder:text-[#9da9bb] placeholder:text-sm !border-[#cccccc] hover:!border-[#b3b3b3] dark:!bg-bgDarkColor dark:text-gray-200 w-full !h-[38px] !rounded !p-2;
}

.input-checkbox-class {
  @apply !shadow focus:!shadow !border-[1px] !border-[#cccccc] hover:!border-[#b3b3b3] dark:!bg-bgDarkColor dark:text-gray-200 !h-[26px] !w-[26px] !rounded !p-2 focus:!ring-0 focus:outline-none;
}

.button-style {
  @apply p-1.5 px-2.5 rounded shadow dark:shadow-none font-semibold;
}

.button-green {
  @apply p-1.5 px-2.5 rounded bg-green-500 hover:bg-green-600 dark:bg-green-700 dark:hover:bg-green-800 text-white shadow dark:shadow-none font-semibold;
}

.button-blue {
  @apply p-1.5 px-2.5 rounded bg-blue-500 hover:bg-blue-600 dark:bg-blue-700 dark:hover:bg-blue-800 text-white shadow dark:shadow-none font-semibold;
}

.button-pay {
  @apply p-1.5 px-2.5 rounded bg-blue-500 hover:bg-blue-600 dark:bg-blue-700 dark:hover:bg-blue-800 text-white shadow dark:shadow-none font-normal;
}

.info-value {
  @apply text-gray-600 dark:text-gray-300 h-6 text-[13px] font-[500] pt-0.5;
}

.info-label {
  @apply text-[#a0a0a0] text-[12px];
}

.sidebar-text-color {
  @apply text-white dark:text-gray-400;
}


.StripeElement {
	background-color: #f8f8f8;
	padding: 10px;
	max-width: 90%;
	border: 2px solid #e0dddd;
}

::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background-color: #427dab;
  border-radius: 20px;
}


@tailwind base;
@tailwind components;
@tailwind utilities;
